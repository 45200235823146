import Turbolinks from "turbolinks"
import 'core-js/features/array/from';

import Glide from '@glidejs/glide'
import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

Turbolinks.start();

function initPhotoSwipe(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
        // var clickedGallery = clickedListItem.closest('.my-gallery'),
            childNodes = clickedListItem.parentNode.childNodes,
            // childNodes = clickedListItem.closest('.my-gallery').getElementsByClassName('add-img'),
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }

        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            },
            bgOpacity: 0.9,
            history: false
        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );
    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }
};

function initializeTwitter(){
  var tw = document.getElementById("twitter-widget");

  if(!tw){ return; }

  twttr.ready(function(){
    twttr.widgets.load();
  })
};

function load_more() {
  const el = document.getElementsByClassName('toggle');

  if (!el.length) { return; }

  Array.from(el).forEach((e) => {
    e.addEventListener('click', (l) => {
      l.preventDefault();

      parent = l.target.parentNode.parentNode
      l.target.textContent === "+" ? l.target.textContent = "-" : l.target.textContent = "+"
      l.target.classList.toggle('more');

      parent.getElementsByClassName('dots')[0].classList.toggle('active')
      parent.getElementsByClassName('text')[0].classList.toggle('open')
    })
  });
};

function toggle_menu() {
    var el = document.querySelector('#nav .menu');

    el.addEventListener('click', (e) => {
      event.preventDefault();

      document.querySelector('#nav .main').classList.toggle('active')
    // $('#nav .menu').on( "click", function() {
    //     $('#nav .main').slideToggle();
    //     event.preventDefault();
    });
}

document.addEventListener('turbolinks:load', function(){

  var el = document.querySelector('.slideshow');
  if (el && el.querySelectorAll('.glide__slide').length) {
    new Glide(el, {
      type: 'carousel',
      autoplay: 4000,
    }).mount();
  }

  initPhotoSwipe('.js-gallery');
  initializeTwitter();
  load_more();
  toggle_menu();
});


document.addEventListener('turbolinks:before-cache', () => {
  const el = document.getElementById('twitter-widget');
  if (el) {
    el.innerHTML = '<a class="twitter-timeline" href="https://twitter.com/bonobostudio">Tweets by @bonobostudio</a>'
  }
});
